import { FiArrowLeft, FiArrowRight, FiX } from 'react-icons/fi';

import styles from './styles.module.scss';

export const OffersGalleryFooter = ({
  gotoPrevious,
  gotoNext,
  closeGallery,
  offersLength,
  currentImageIndex,
}) => {
  return (
    <footer className={styles.container}>
      <span className={styles.whitespace}></span>

      <div className={styles.controls}>
        <button
          onClick={gotoPrevious}
          className={styles.controlButton}
          disabled={currentImageIndex === 0}
        >
          <FiArrowLeft />
        </button>
        <button
          onClick={gotoNext}
          className={styles.controlButton}
          disabled={currentImageIndex + 1 === offersLength}
        >
          <FiArrowRight />
        </button>
      </div>

      <button onClick={closeGallery} className={styles.controlButton}>
        <FiX />
      </button>
    </footer>
  );
};
