import React from 'react';
import ReactDOM from 'react-dom';

import 'keen-slider/keen-slider.min.css';
import './styles/globals.css';

import { Router } from './Router';

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById('root')
);
