import React, { useEffect, useState } from 'react';
import Lightbox from 'react-spring-lightbox';
import { useParams } from 'react-router-dom';

import { Tasks } from './components/Tasks';
import { OffersGalleryFooter } from './components/OffersGalleryFooter';

import baseColors from './assets/baseColors';
import styles from './App.module.scss';

const App = () => {
  const [tasks, setTasks] = useState();
  const [error, setError] = useState();
  const [colors, setColors] = useState();
  const [offers, setOffers] = useState([]);
  const [showOffersModal, setShowOffersModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const params = useParams();

  function toggleOffersModal() {
    setShowOffersModal((state) => !state);
  }

  function gotoPrevious() {
    currentImageIndex > 0 && setCurrentImageIndex(currentImageIndex - 1);
  }

  function gotoNext() {
    currentImageIndex + 1 < offers.length &&
      setCurrentImageIndex(currentImageIndex + 1);
  }

  function closeGallery() {
    setShowOffersModal(false);
    setCurrentImageIndex(0);
  }

  useEffect(() => {
    if (!params.bioId) {
      return;
    }

    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_GET_BIO_URL}${params.bioId}`
        );

        const data = await response.json();
        console.log(data);

        if (data.company) {
          document.title = data.company;
        } else {
          document.title = 'Link Page';
        }

        if (data.list) {
          setTasks(data.list);
        } else {
          setError('Página de links não encontrada.');
          setColors(baseColors);
          throw new Error('Página de links não existe.');
        }

        const { company } = data.list[0];

        const colorsResponse = await fetch(
          `${process.env.REACT_APP_GET_LINKSPAGE_COLORS}`,
          {
            headers: new Headers({
              Company: company,
            }),
          }
        );

        const colorsData = await colorsResponse.json();

        if (colorsData) {
          setColors(colorsData.colors);
        } else {
          setColors(baseColors);
          console.log(
            'O perfil não possui cores configuradas.\nCores default aplicadas.'
          );
        }

        const offersHeaders = new Headers({
          Company: company,
        });

        const offersResponse = await fetch(
          process.env.REACT_APP_GET_OFFERS +
            '&expirationDate=' +
            new Date().toISOString().slice(0, 10),
          {
            headers: offersHeaders,
          }
        );

        const offersData = await offersResponse.json();

        if (offersData.list) {
          const offers = offersData.list.map((offer) => {
            return {
              src: offer.file,
              alt: 'Oferta',
              loading: 'lazy',
            };
          });

          setOffers(offers);
        }
      } catch (error) {
        console.error(
          'Houve um erro ao buscar dados da página de links\n',
          error
        );
      }
    })();
  }, [params.bioId]);

  return (
    <div
      className={styles.container}
      style={
        colors?.backgroundColor
          ? { backgroundColor: colors?.backgroundColor, opacity: 1 }
          : {}
      }
    >
      <div className={styles.content}>
        {error ? (
          <h2 className={styles.error}>{error}</h2>
        ) : (
          tasks &&
          colors && (
            <Tasks
              tasks={{
                tasks,
                hasOffers: offers.length > 0,
                toggleOffersModal,
                colors,
              }}
            />
          )
        )}
      </div>

      {showOffersModal && offers.length > 0 && (
        <div className={styles.offersModal}>
          <Lightbox
            isOpen={showOffersModal}
            onPrev={gotoPrevious}
            onNext={gotoNext}
            images={offers}
            currentIndex={currentImageIndex}
            renderFooter={() =>
              OffersGalleryFooter({
                gotoNext,
                gotoPrevious,
                closeGallery,
                currentImageIndex,
                offersLength: offers.length,
              })
            }
            className="bg-translucentGray"
          />
        </div>
      )}
    </div>
  );
};

export default App;
