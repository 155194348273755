import styles from './styles.module.scss';

export const Youtube = () => {
  return (
    <div className={styles.container}>
      <iframe
        width="360"
        height="215"
        src="https://www.youtube.com/embed/pWjmpSD-ph0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};
