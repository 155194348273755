import styles from './styles.module.scss';

export const Title = ({ type, data, color }) => {
  const { typefontsize, titleText } = data.title;

  return (
    <div className={styles.container}>
      {(() => {
        switch (typefontsize) {
          case 'h1':
            return <h1 style={{ color }}>{titleText}</h1>;
          case 'h2':
            return <h2 style={{ color }}>{titleText}</h2>;
          case 'h3':
            return <h3 style={{ color }}>{titleText}</h3>;
          case 'h4':
            return <h4 style={{ color }}>{titleText}</h4>;
          case 'h5':
            return <h5 style={{ color }}>{titleText}</h5>;
          case 'h6':
            return <h6 style={{ color }}>{titleText}</h6>;
          default:
            break;
        }
      })()}
    </div>
  );
};
