import styles from './styles.module.scss';

export const Offers = ({ toggleOffersModal, colors }) => {
  return (
    <div className={styles.container}>
      <button
        onClick={toggleOffersModal}
        style={{
          backgroundColor: colors.buttonColor,
          color: colors.buttonTextColor,
        }}
      >
        Ver ofertas
      </button>
    </div>
  );
};
