import styles from './styles.module.scss';

export const Paragraph = ({ type, data, color }) => {
  const text = data.paragraph.paragraphtext;
  return (
    <div className={styles.container}>
      <div>
        <p style={{ color }}>{text}</p>
      </div>
    </div>
  );
};
