import React from 'react';
import Task from '../task';

import { Links } from '../Links';
import { Avatar } from '../Avatar';
import { Title } from '../Title';
import { Paragraph } from '../Paragraph';
import { SocialMedia } from '../SocialMedia';
import { Youtube } from '../Youtube/index';

import styles from './styles.module.scss';
import { Offers } from '../Offers';

export const Tasks = ({
  tasks: { tasks, hasOffers, toggleOffersModal, colors },
}) => {
  const {
    backgroundColor,
    buttonColor,
    buttonTextColor,
    pageTextColor,
    socialButtonsColor,
  } = colors;

  return (
    <div className={styles.container}>
      {tasks.slice(0, 2).length > 0 &&
        tasks.slice(0, 2).map((task) => {
          switch (task.typeLink) {
            case 'link':
              return (
                <Links
                  key={task._id}
                  type="link"
                  data={task}
                  colors={{ buttonColor, buttonTextColor }}
                />
              );
            case 'title':
              return (
                <Title
                  key={task._id}
                  type="title"
                  data={task}
                  color={pageTextColor}
                />
              );
            case 'paragraph':
              return (
                <Paragraph
                  key={task._id}
                  type="paragraph"
                  data={task}
                  color={pageTextColor}
                />
              );
            case 'avatar':
              return <Avatar key={task._id} type="avatar" data={task} />;
            case 'socialmedia':
              return (
                <SocialMedia
                  key={task._id}
                  type="socialmedia"
                  data={task}
                  color={socialButtonsColor}
                  backgroundColor={backgroundColor}
                />
              );
            case 'youtube':
              return <Youtube key={task._id} type="youtube" data={task} />;
            default:
              return <Task key={task._id} type="default" data={task} />;
          }
        })}

      {hasOffers && (
        <Offers
          toggleOffersModal={toggleOffersModal}
          colors={{ buttonColor, buttonTextColor }}
        />
      )}

      {tasks.slice(2).length > 0 &&
        tasks.slice(2).map((task) => {
          switch (task.typeLink) {
            case 'link':
              return (
                <Links
                  key={task._id}
                  type="link"
                  data={task}
                  colors={{ buttonColor, buttonTextColor }}
                />
              );
            case 'title':
              return (
                <Title
                  key={task._id}
                  type="title"
                  data={task}
                  color={pageTextColor}
                />
              );
            case 'paragraph':
              return (
                <Paragraph
                  key={task._id}
                  type="paragraph"
                  data={task}
                  color={pageTextColor}
                />
              );
            case 'avatar':
              return <Avatar key={task._id} type="avatar" data={task} />;
            case 'socialmedia':
              return (
                <SocialMedia
                  key={task._id}
                  type="socialmedia"
                  data={task}
                  color={socialButtonsColor}
                  backgroundColor={backgroundColor}
                />
              );
            case 'youtube':
              return <Youtube key={task._id} type="youtube" data={task} />;
            default:
              return <Task key={task._id} type="default" data={task} />;
          }
        })}

      {!tasks && <h2>Esta página ainda não possui links.</h2>}
    </div>
  );
};
