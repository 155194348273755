import styles from './styles.module.scss';

export const Avatar = ({ type, data }) => {
  const {
    images: [i],
    avatarsize,
    routeradius,
  } = data.avatar;

  return (
    <div className={styles.container}>
      <img
        src={i}
        alt="Foto de perfil"
        style={{
          width: avatarsize + 'px',
          height: avatarsize + 'px',
          borderRadius:
            routeradius === 'round'
              ? '100%'
              : routeradius === 'rounded'
              ? '20%'
              : 0,
        }}
      />
    </div>
  );
};
