import styles from './styles.module.scss';

export const Links = ({ type, data, colors }) => {
  const { destinationurl, name } = data.link;

  const formattedUrl = destinationurl.replace(/^https?:\/\//, '');

  return (
    <div className={styles.container}>
      <a
        key={name}
        href={`https://${formattedUrl}`}
        rel="noreferrer"
        target="_blank"
        style={{
          backgroundColor: colors.buttonColor,
          color: colors.buttonTextColor,
        }}
      >
        {name}
      </a>
    </div>
  );
};
