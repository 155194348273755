import { FiPhone } from 'react-icons/fi';
import { RiMessengerLine } from 'react-icons/ri';
import { SocialIcon } from 'react-social-icons';

import styles from './styles.module.scss';

export const SocialMedia = ({ data, color, backgroundColor }) => {
  const { socialmedia } = data;

  const networkList = Object.entries(socialmedia);

  return (
    <div className={styles.container}>
      {networkList.map(([network, value]) => {
        if (!value) {
          return;
        }
        switch (network) {
          case 'email':
            return (
              value && (
                <SocialIcon
                  key={network}
                  network="email"
                  url={`mailto:${value}`}
                  bgColor={color}
                  label={network}
                  style={{ width: 32, height: 32 }}
                />
              )
            );
          case 'phone':
            return (
              value && (
                <a
                  href={`tel:${value}`}
                  title="Telefone"
                  target="_blank"
                  rel="noreferrer"
                  key={network}
                >
                  <div
                    className={styles.iconWrapper}
                    style={{ backgroundColor: color }}
                  >
                    <FiPhone size={16} color={backgroundColor} />
                  </div>
                </a>
              )
            );
          case 'telegram':
            return (
              value && (
                <SocialIcon
                  key={network}
                  network="telegram"
                  url={`https://t.me/${value}`}
                  bgColor={color}
                  label={network}
                  style={{ width: 32, height: 32 }}
                  target="_blank"
                />
              )
            );
          case 'whatsapp':
            return (
              value && (
                <SocialIcon
                  key={network}
                  network="whatsapp"
                  url={`https://wa.me/+55${value.replace(/\D/g, '')}`}
                  bgColor={color}
                  label={network}
                  style={{ width: 32, height: 32 }}
                  target="_blank"
                />
              )
            );
          case 'facebook':
            return (
              value && (
                <SocialIcon
                  key={network}
                  network="facebook"
                  url={`https://www.facebook.com/${value}`}
                  bgColor={color}
                  label={network}
                  style={{ width: 32, height: 32 }}
                  target="_blank"
                />
              )
            );
          case 'facebookmessage':
            return (
              value && (
                <a
                  href={`https://m.me/${value}`}
                  title="Messenger"
                  target="_blank"
                  rel="noreferrer"
                  key={network}
                >
                  <div
                    className={styles.iconWrapper}
                    style={{ backgroundColor: color }}
                  >
                    <RiMessengerLine size={18} color={backgroundColor} />
                  </div>
                </a>
              )
            );
          case 'instagram':
            let formattedInstagramUsername = value;

            while (formattedInstagramUsername[0] === '@') {
              formattedInstagramUsername = formattedInstagramUsername.slice(1);
            }

            return (
              value && (
                <SocialIcon
                  key={network}
                  network="instagram"
                  url={`https://www.instagram.com/${formattedInstagramUsername}`}
                  bgColor={color}
                  label={network}
                  style={{ width: 32, height: 32 }}
                  target="_blank"
                />
              )
            );
          case 'twitter':
            return (
              value && (
                <SocialIcon
                  key={network}
                  network="twitter"
                  url={`https://twitter.com/${value}`}
                  bgColor={color}
                  label={network}
                  style={{ width: 32, height: 32 }}
                  target="_blank"
                />
              )
            );
          case 'tiktok':
            return (
              value && (
                <SocialIcon
                  key={network}
                  network="tiktok"
                  url={`https://www.tiktok.com/@${value}`}
                  bgColor={color}
                  label={network}
                  style={{ width: 32, height: 32 }}
                  target="_blank"
                />
              )
            );
          case 'youtubechannel':
            return (
              value && (
                <SocialIcon
                  key={network}
                  network="youtube"
                  url={`https://www.youtube.com/channel/${value}`}
                  bgColor={color}
                  label={network}
                  style={{ width: 32, height: 32 }}
                  target="_blank"
                />
              )
            );
          case 'soundcloud':
            return (
              value && (
                <SocialIcon
                  key={network}
                  network="soundcloud"
                  url={`https://soundcloud.com/${value}`}
                  bgColor={color}
                  label={network}
                  style={{ width: 32, height: 32 }}
                  target="_blank"
                />
              )
            );
          case 'linkedin':
            return (
              value && (
                <SocialIcon
                  key={network}
                  network="linkedin"
                  url={`https://www.linkedin.com/in/${value}`}
                  bgColor={color}
                  label={network}
                  style={{ width: 32, height: 32 }}
                  target="_blank"
                />
              )
            );
          case 'spotify':
            return (
              value && (
                <SocialIcon
                  key={network}
                  network="spotify"
                  url={`https://open.spotify.com/user/${value}`}
                  bgColor={color}
                  label={network}
                  style={{ width: 32, height: 32 }}
                  target="_blank"
                />
              )
            );
          case 'pinterest':
            return (
              value && (
                <SocialIcon
                  key={network}
                  network="pinterest"
                  url={`https://br.pinterest.com/${value}`}
                  bgColor={color}
                  label={network}
                  style={{ width: 32, height: 32 }}
                  target="_blank"
                />
              )
            );
          case 'snapchat':
            return (
              value && (
                <SocialIcon
                  key={network}
                  network="snapchat"
                  url={`https://snapchat.com/add/${value}`}
                  bgColor={color}
                  label={network}
                  style={{ width: 32, height: 32 }}
                  target="_blank"
                />
              )
            );
          case 'twitch':
            return (
              value && (
                <SocialIcon
                  key={network}
                  network="twitch"
                  url={`https://www.twitch.tv/${value}`}
                  bgColor={color}
                  label={network}
                  style={{ width: 32, height: 32 }}
                  target="_blank"
                />
              )
            );
          case 'discord':
            return (
              value && (
                <SocialIcon
                  key={network}
                  network="discord"
                  url={`https://discordapp.com/users/${value}`}
                  bgColor={color}
                  label={network}
                  style={{ width: 32, height: 32 }}
                  target="_blank"
                />
              )
            );
          default:
            return false;
        }
      })}
    </div>
  );
};
